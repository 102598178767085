// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React, { ReactNode } from "react";
import { ContentItem } from "types/content";
import EditableSection from "./contentEditing/EditableSection";
import PostsContent from "./content/PostsContent";
import { observer } from "mobx-react-lite";
import { useMember } from "hooks";
import { memberHasRoleAccess } from "lib/auth";
interface Props {
  itemClass?: string;
  leadingContent?: ReactNode;
  postsItem?: ContentItem | null | undefined;
}
function PostContainer(props: Props) {
  const {
    leadingContent,
    postsItem
  } = props;
  const member = useMember();
  const canEdit = memberHasRoleAccess(member?.account, "content");
  return <div className="w-full flex flex-col flex-wrap justify-center" data-sentry-component="PostContainer" data-sentry-source-file="PostContainer.tsx">
      {leadingContent}
      <EditableSection contentId={postsItem?.id} data-sentry-element="EditableSection" data-sentry-source-file="PostContainer.tsx">
        {postsItem?.children && postsItem?.children.length > 0 ? <PostsContent postsItem={postsItem} itemClass={props.itemClass} /> : canEdit && <div>
              <span className="font-bold">No posts found</span> To add content
              posts to this page, press the Edit button in the lower-right
              corner of the page.
            </div>}
      </EditableSection>
    </div>;
}
export default observer(PostContainer);